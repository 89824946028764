import React, { useEffect } from 'react'
import 'twin.macro'

import SEO from 'components/shared/seo'
import useLocationNewYork from 'content-queries/mx/location-new-york'
import fireEvent from 'context/tracking/events'
import { AutomaticPopup } from 'mx/automatic-popup'
import Hero from 'mx/components/hero'
import { TextDivider } from 'mx/components/text-divider'
import { Press } from 'mx/homepage/press'
import Layout from 'mx/layout'
import { BottomCta } from 'mx/locations/bottom-cta'
import { SectionContent } from 'mx/locations/section-content'
import { SectionContentFull } from 'mx/locations/section-content-full'

const NewYorkPage = () => {
  useEffect(() => {
    fireEvent({
      type: 'page_viewed',
      pageTitle: 'New York Studio',
    })
  }, [])

  const { data } = useLocationNewYork()
  const [
    {
      node: {
        sectionModules: [
          hero,
          quality,
          functionality,
          appointment,
          home,
          pricing,
          map,
          bottomCta,
        ],
      },
    },
  ] = data

  return (
    <Layout>
      <AutomaticPopup />
      <Hero data={hero} />
      <TextDivider title="As seen in" />
      <Press hideQuotes={true} />
      <SectionContent data={quality} isLink={true} />
      <SectionContent data={functionality} />
      <SectionContentFull data={appointment} />
      <SectionContent data={home} />
      <SectionContent data={pricing} />
      <SectionContent data={map} />
      <BottomCta data={bottomCta} />
    </Layout>
  )
}
export default NewYorkPage

export const Head = () => {
  const { data } = useLocationNewYork()
  const [
    {
      node: { image },
    },
  ] = data

  return (
    <SEO
      description="Visit our New York studio to experience our modern German cabinetry and start your project. Book a visit today"
      path="/new-york"
      title="New York Studio"
      image={
        image.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src
      }
    />
  )
}
