import React from 'react'
import 'twin.macro'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { motion } from 'framer-motion'
import tw from 'twin.macro'

import { containerVariants, itemVariants } from './section-components'
import useDisplay from '../hooks/useDisplay'

export type ListStatsItem = {
  contentSlots: {
    slotTitle: string
    description?: string
    meta?: {
      icon?: string
    }
    photos?: {
      file?: {
        url?: string
      }
    }[]
  }
}

type ListStatsProps = {
  items: ListStatsItem[]
  padding?: boolean
}

const ListStats = ({ items, padding = true }: ListStatsProps) => {
  const intersectionRef = React.useRef(null)
  const showIt = useDisplay(intersectionRef)

  return (
    <div
      ref={intersectionRef}
      tw="flex relative w-full items-start mt-24 md:(flex-wrap max-w-screen-xl) lg:(mx-auto)"
    >
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate={showIt ? 'show' : 'hidden'}
        css={[!padding && tw`!px-0`]}
        tw="flex flex-col px-6 w-full space-y-12 md:(flex-row grow justify-between space-x-8 space-y-0)"
      >
        {items?.map((item: any, index: number) => {
          return (
            <motion.div
              key={index}
              variants={itemVariants}
              tw="w-full flex flex-row items-center relative space-x-2 md:(w-auto justify-between)"
            >
              {item.meta?.icon && (
                <div tw="flex w-12 h-full items-center justify-center text-gray-400 md:(mx-auto)">
                  <FontAwesomeIcon
                    tw="w-6 h-6"
                    icon={['fal', item.meta?.icon]}
                  />
                </div>
              )}
              <div>
                {item?.photos ? (
                  <div tw="flex space-x-2 mb-2">
                    {item?.photos.map((logo: any, index: number) => {
                      return <img key={index} src={logo?.file?.url} tw="h-6" />
                    })}
                  </div>
                ) : (
                  <p tw="text-black text-2xl font-thin leading-tight">
                    {item?.slotTitle}
                  </p>
                )}
                <p
                  tw="text-sm text-gray-500"
                  dangerouslySetInnerHTML={{
                    __html: item?.description,
                  }}
                />
              </div>
            </motion.div>
          )
        })}
      </motion.div>
    </div>
  )
}

export default ListStats
